import './App.css';
import axios from 'axios';
import { Grid, Typography, CircularProgress, TextField, Button,
  FormGroup, FormControlLabel, Checkbox, FormControl, 
  Radio, RadioGroup, Divider, Dialog, DialogTitle, DialogContent,
  DialogActions, DialogContentText} from '@mui/material';
import {  BrowserRouter, Routes, Route, Link} from "react-router-dom";
import React, { useState, useEffect } from 'react'
import {send_palserverAPI_request} from './send_serverAPI_request';
import { v4 as uuidv4 } from 'uuid';
import validator from 'validator';



import QRCode from "qrcode";

const QUERY1_SRC = "https://fi.research.net/r/Y2WT6MK";
//const QUERY1_SRC = "http://127.0.0.1:6100";
const QUERY2_SRC = "https://fi.research.net/r/PMQM2S9";
//const QUERY2_SRC = "http://127.0.0.1:6100";

export default function App() {

  const BASE_URL = window.location.origin + "/";
  let surveyId = window.location.href.split(BASE_URL).slice(-1).toString();

  const [firstEntry, setFirstEntry] = useState(true);
  const [failed, setFailed] = useState(false)
  const [qrInfo, setQrInfo] = useState({});
  const [kiitosInfo, setKiitosInfo] = useState(undefined);
  const [surveyDone, setSurveyDone] = useState(false);
  const [lottery, setLottery] = useState('yes');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [email2, setEmail2] = useState('');
  const [errors, setErrors] = useState('');
  const [tel, setTel] = useState('');
  const [gdprAgreedLottery, setGdprAgreedLottery] = useState(false);
  const [gdprAgreedExtended, setGdprAgreedExtended] = useState(false);
  const [gdprAgreedPartner, setGdprAgreedPartner] = useState(false);
  const [answer2nd, setAnswer2nd] = useState(surveyId.includes("haggman") ? 'yes' : 'no');
  const [moveTo2nd, setMoveTo2nd] = useState(false);
  const [openEndDialog, setOpenEndDialog] = useState(false);
  const [waiting, setWaiting] = useState(false);
  let QRINFO = {};

  const serverReqErrorHandler = (errorid, code) => {
    console.log('App: ERROR: ', errorid, code);
    setFailed(true);
  };


  // This hook is listening an event that came from the Iframe
  useEffect(() => {
    const handler = (ev) => {


      if (typeof ev.data !== 'object') return
      if (!ev.data.type) return
      if (ev.data.type !== 'button-click') return
      if (!ev.data.message) return

      let key = qrInfo.key;
      let baseurl = BASE_URL + "kiitos/";

      //console.log("qrInfo", qrInfo, "location:", window.location.href, QRINFO)
      let qrInfoTemp = JSON.parse(JSON.stringify(QRINFO));
      qrInfoTemp.end_time = new Date().getTime();

      window.removeEventListener('message', handler)
      QRCode.toDataURL(baseurl + key)
      .then(url => {
        //console.log('generoitu url: ', url); 
      
        send_palserverAPI_request(
        'patch',
        '/survey-api/surveydones/' + QRINFO.key,
        '',
        qrInfoTemp,
        'ERROR_QR_REG',
        serverReqErrorHandler,
        response => {
          //console.log("Serveriltä vastaus eka patchiin!!!", response)
          setQrInfo(response);
          setSurveyDone(true);
        }
        )
    })
    .catch(err => {console.log(err)})
    }

    window.addEventListener('message', handler)

  }, [])

  const handleChange = (event) => {
    if (event.target.id === "name") {
      setName(validator.escape(event.target.value));
    } else if (event.target.id === "email") {
      setEmail(validator.escape(event.target.value));
    } else if (event.target.id === "email2") {
      setEmail2(validator.escape(event.target.value));
    } else if (event.target.id === "tel") {
      setTel(validator.escape(event.target.value));
    } 
  };

  const handleLotteryRadioGroupChange = (event) => {
    setLottery(event.target.value);
  };

  const handleGdprAgreedLotteryChange = (event) => {
    setGdprAgreedLottery(event.target.checked);
  };

  const handleGdprAgreedExtendedChange = (event) => {
    setGdprAgreedExtended(event.target.checked);
  };

  const handleGdprAgreedPartnerChange = (event) => {
    setGdprAgreedPartner(event.target.checked);
  };

  const handleRadioGroupChange = (event) => {
    setAnswer2nd(event.target.value);
  };



  const handleSendClick = () => {
    
    let nonvalidinput = false;
    let errors = {};

    /*if (lottery.includes("yes") && email !== undefined && !validator.isEmail(email)) {
      nonvalidinput = true;
      errors["email"] = "Sähköpostiosoite on väärässä muodossa";
    }
    if (lottery.includes("yes") && tel !== undefined && 
      (!validator.isMobilePhone(tel.replace('-','').replace('(0)','')) ||
       !validator.isLength(validator.trim(tel), {min:6, max: 20}))) {
      nonvalidinput = true;
      errors["tel"] = "Puhelinnumerosi on väärässä muodossa";
    }*/

    if (!nonvalidinput) {

      let nameTemp = name;
      let emailTemp = email;
      let telTemp = tel;
      let gdprAgreedLotteryTemp = gdprAgreedLottery;
      let gdprAgreedExtendedTemp = gdprAgreedExtended;
      if (lottery.includes("no")) {
        nameTemp = '';
        emailTemp = '';
        telTemp = '';
        gdprAgreedLotteryTemp = false;
        gdprAgreedExtendedTemp = false;
      }

      let qrInfoTemp = JSON.parse(JSON.stringify(qrInfo));
      qrInfoTemp.lottery = lottery.includes("yes");
      qrInfoTemp.name = nameTemp; 
      qrInfoTemp.email = emailTemp; 
      qrInfoTemp.tel = telTemp;
      qrInfoTemp.agreedToGDPRLottery = gdprAgreedLotteryTemp;
      qrInfoTemp.agreedToGDPRExtended = gdprAgreedExtendedTemp;
      qrInfoTemp.answer2nd = answer2nd;

      setWaiting(true)
      send_palserverAPI_request(
        'patch',
        '/survey-api/surveydones/' + qrInfo.key,
        '',
        qrInfoTemp,
        'ERROR_CLICK_SEND',
        serverReqErrorHandler,
        response => {
          setQrInfo(response);
          setWaiting(false)

        }
      )
      setOpenEndDialog(true)
    } else {
      setErrors(errors);
    }
  }

  const handleSend = (cancelled) => {

    //console.log("handleSend, cancelled:", cancelled)

    let gdprAgreedPartnerTemp = gdprAgreedPartner;
    if (cancelled) {
      gdprAgreedPartnerTemp = false;
    }


    if (lottery.includes("no") && gdprAgreedPartnerTemp) {
      emailTemp = email2
    }


    let qrInfoTemp = JSON.parse(JSON.stringify(qrInfo));
    qrInfoTemp.email = emailTemp; 
    qrInfoTemp.agreedToGDPRPartner = gdprAgreedPartnerTemp;
    qrInfoTemp.checkout_time = new Date().getTime();

    send_palserverAPI_request(
      'patch',
      '/survey-api/surveydones/' + qrInfo.key,
      '',
      qrInfoTemp,
      'ERROR_CLICK_SEND',
      serverReqErrorHandler,
      response => {
        //console.log("Serveriltä vastaus!!!", response)
        if (qrInfo.surveyId.includes("palbassador") ||
            answer2nd.includes("no") || answer2nd.includes("later")) {
          window.location.replace("https://www.haggman.fi");
        } else {
          window.location.replace("https://www.palautekanava.fi/palbassador/" + qrInfo.key);
          //window.location.replace("http://localhost:5100/palbassador/" + qrInfo.key);
        }
      }
    )
    setWaiting(true)
  }



  if (window.location.href.includes(BASE_URL + 'surveydone')) {
    window.parent.postMessage(
      {
        type: 'button-click',
        message: "terve",
      },
      '*'
    )
  }

  if (firstEntry &&
      ((window.location.href.includes(BASE_URL + 'haggman')) || 
       (window.location.href.includes(BASE_URL + 'palbassador'))  )) {
    
    //console.log("EKA KERTA!!!", window.location.href)
    let tempHref=window.location.href.split(BASE_URL).slice(-1).toString();
    let tempHref2 = tempHref.split('/');
    let surveyId = tempHref2[0];
    let refKey = tempHref2[1];
    if (refKey === undefined) {refKey = ''}
    //console.log("surveyId:", surveyId, "refKey:", refKey)
    
    let key = uuidv4();
    let baseurl = BASE_URL + "kiitos/";

    send_palserverAPI_request(
      'post',
      '/survey-api/surveydones',
      '',
      {surveyId: surveyId,
       baseurl: baseurl,
       key: key, 
       refKey: refKey,
       qrSrcUrl: "url", //url,
       lottery: false,
       name: name,
       email: email,
       tel: tel,
       agreedToGDPRLottery: false,
       agreedToGDPRExtended: false,
       agreedToGDPRPartner: false,
       answer2nd: 'no',
       start_time: new Date().getTime(),
       end_time: 0,
       checkout_time: 0},
      'ERROR_QR_REG',
      serverReqErrorHandler,
      response => {
        //console.log("Serveriltä vastaus!!!", response)
        QRINFO= JSON.parse(JSON.stringify(response));
        setQrInfo(response);
        setFirstEntry(false);        
      }
    )
  }
  


  let querySrc = QUERY1_SRC;
  if (surveyId.includes("palbassador")) {
    querySrc = QUERY2_SRC
  }


  //console.log("APP.js RENDER!", answer2nd, surveyId, window.location, qrInfo)

  let emailTemp = email;
  if (lottery.includes('no')) {
    emailTemp = '';
  }
  let buttonDisabled = lottery.includes("yes") && 
  (name.length < 1 || (email.length < 1 && tel.length < 1) ||
   !gdprAgreedLottery)

  return (
      <div style={{height:"99vh", width:"99vw"}}>
      <BrowserRouter>
        <Routes>
          {["/haggman", "/palbassador", "/palbassador/*"].map((path,index) =>
          <Route path={path} key={index} element={
              (surveyDone) ? 
              <Grid container direction="row" 
                    justifyContent="space-around"
                    style={{height:"100%", width:"100%", padding:20}}>            
                <Grid item xs={12} style={{width:"100%", maxWidth:1000}}>
                  <Grid container direction="column" 
                        justifyContent="space-around"
                        style={{paddingTop:10, height:"100%"}}>
                    {/*<Grid item style={{padding:10, backgroundColor:"red"}}>
                      <img alt="HaggmanLogo" src="https://www.haggman.fi/wp-content/themes/haggman/img/logo.png"></img>
          </Grid>*/}
                    <Grid item style={{padding:10}}>
                      <Typography align="center" variant="h5"
                        style={{padding:20}}>Iso kiitos vastauksestasi!</Typography>
                    </Grid>
                    <Grid item style={{backgroundColor:"white", paddingTop:10}}>
                      {surveyId.includes("haggman") ?
                      <Typography >
                        Arvomme kaikkien kyselyyn netissä tai kahviloissa vastanneiden kesken 3 kappaletta 10 hengen kermakakkuja. {' '}
                        <Link to="/haggman/arvonta">
                          Arvonnan säännöt
                        </Link>
                      </Typography>
                      :
                      <Typography >
                        Arvomme kaikkien kyselyyn vastanneiden kesken 2 kappaletta 50€:n arvoisia Häggmanin lahjakortteja. {' '}
                        <Link to="/palbassador/arvonta">
                          Arvonnan säännöt
                        </Link>
                      </Typography>
                       }
                    </Grid >
                    <Grid item style={{backgroundColor:"white", paddingTop:20}}>
                    <Grid >
                      <Typography sx={{fontWeight: 'bold'}}>
                      Haluatko osallistua arvontaan?
                      </Typography>
                    </Grid >
                    <FormControl style={{margin:10}}>
                      <RadioGroup
                        aria-labelledby="lottery-radio-buttons-group-label"
                        name="lottery-radio-buttons-group"
                        value={lottery}
                        onChange={handleLotteryRadioGroupChange}
                      >
                        <FormControlLabel value="yes" control={<Radio />} label="Kyllä, osallistun arvontaan" />
                          <Grid item style={{marginLeft:30}}>
                            <Grid container direction="column" 
                                  justifyContent="flex-start"
                                  alignItems="left">
                              <Grid item style={{backgroundColor:"white", paddingTop:10}}>
                                <Typography>
                                  {"Yhteystiedot palkinnosta ilmoittamista varten (ei yhdistetä antamiisi kyselyvastauksiin)"}
                                </Typography>
                              </Grid >
                              <TextField style={{width:"100%", margin:5}} 
                                id="name" 
                                disabled={lottery.includes("no")}
                                label="Nimi*" variant="standard"
                                value={lottery.includes("yes") ? name : ''}
                                onChange={handleChange}/>
                              <TextField style={{width:"100%", margin:5}} 
                                id="email" 
                                disabled={lottery.includes("no")}
                                label="E-mail*" variant="standard"
                                value={lottery.includes("yes") ? email : ''}
                                onChange={handleChange}/>
                              <Typography variant="caption"
                                style={{paddingLeft:5, paddingBottom:5, color:"red"}}>
                                {errors["email"]}</Typography>
                              <TextField style={{width:"100%", margin:5}} 
                                id="tel" 
                                disabled={lottery.includes("no")}
                                label="Puhelinnumero*" variant="standard"
                                value={lottery.includes("yes") ? tel : ''}
                                onChange={handleChange}/>
                              <Typography variant="caption"
                                style={{paddingLeft:5, paddingBottom:5, color:"red"}}>
                                {errors["tel"]}
                              </Typography>
                              <FormGroup style={{margin:5}}>
                                <FormControlLabel control={
                                  <Checkbox disabled={lottery.includes("no")}
                                            checked={lottery.includes("yes") && gdprAgreedLottery} 
                                            onChange={handleGdprAgreedLotteryChange}
                                            inputProps={{ 'aria-label': 'controlled' }}/>} 
                                  label={
                                    (surveyId.includes("haggman") ?
                                    <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                                      *Hyväksyn henkilötietojeni käsittelyn Häggman Oy:n {''}
                                      <Link to="/haggman/arvontarekisteri">tietosuojaselosteen</Link> 
                                      {' '}mukaisesti
                                    </Typography>
                                    :
                                    <Typography variant="body2" sx={{fontWeight: 'bold'}} >
                                    *Hyväksyn henkilötietojeni käsittelyn Palbassador Oy:n {''}
                                    <Link to="/palbassador/arvontarekisteri">tietosuojaselosteen</Link> 
                                    {' '}mukaisesti
                                  </Typography>)
                                  }/>
                                <FormControlLabel style={{marginTop: 8}} control={
                                  <Checkbox disabled={lottery.includes("no")}
                                            checked={lottery.includes("yes") && gdprAgreedExtended} 
                                            onChange={handleGdprAgreedExtendedChange}
                                            inputProps={{ 'aria-label': 'controlled' }}/>} 
                                  label={
                                    (surveyId.includes("haggman") ?
                                    <Typography variant="body2" >
                                      Minulle saa lähettää ilmoituksia Häggman Oy:n palveluiden kehittämiseen liittyvistä kyselyistä, tutkimuksista ja arvonnoista
                                    </Typography>
                                    :
                                    <Typography variant="body2" >
                                      Minulle saa lähettää ilmoituksia Palbassador Oy:n palveluiden kehittämiseen liittyvistä kyselyistä, tutkimuksista ja arvonnoista
                                    </Typography>
                                    )
                                  }/>
                              </FormGroup>
                            </Grid>
                          </Grid>
                        <FormControlLabel value="no" control={<Radio />} label="En osallistu arvontaan" />
                        
                      </RadioGroup>
                    </FormControl>
                    </Grid>

                    {surveyId.includes("haggman") ?
                    <>
                    <Divider light style={{padding:20}} />
                    <Grid item style={{backgroundColor:"white", paddingTop:40}}>
                      <Typography sx={{fontWeight: 'bold'}}>
                       Haluaisitko vastata vielä toiseen, yleisiä kulutustottumuksia ja asenteita selvittävään kyselyyn?
                      </Typography>
                    </Grid >
                    <Grid item style={{backgroundColor:"white", paddingTop:10}}>
                      <Typography >
                        Kyselyyn 16.3.2023 mennessä vastanneiden kesken{' '} 
                        <span style={{fontWeight:'bold', color:"#548235"}}> 
                           arvotaan 2 kappaletta 50€:n arvoisia Häggmanin lahjakortteja.
                        </span>
                        
                      </Typography>
                    </Grid >
                    <Grid item style={{backgroundColor:"white", paddingTop:10, paddingBottom:10}}>
                      <Typography >
                        Vastaamiseen kuluu aikaa arviolta noin 15 minuuttia. Kysely on yhteistyökumppanimme Palbassador Oy:n järjestämä ja sen tuloksia käytetään uusien digitaalisten palveluiden kehittämiseen. 
                      </Typography>
                    </Grid >
                    <FormControl style={{margin:10}}>
                      <RadioGroup
                        aria-labelledby="answer2nd-radio-buttons-group-label"
                        name="answer2nd-radio-buttons-group"
                        value={answer2nd}
                        onChange={handleRadioGroupChange}
                      >
                        <FormControlLabel value="yes" control={<Radio />} label="Kyllä, vastaan heti" />
                        <FormControlLabel value="later" control={<Radio />} label="Myöhemmin" />
                        <FormControlLabel value="no" control={<Radio />} label="Ei" />
                    </RadioGroup>
                    </FormControl>
                    </>
                    : null}
                    <Grid item style={{padding:10, marginBottom:20}}>
                      <Grid container direction="column" 
                            justifyContent="flex-start"
                            alignItems="center">
                        <Button variant="contained"
                          disabled={buttonDisabled ? true : false}
                          onClick={handleSendClick}>
                            {"Lähetä"}
                            </Button>
                        {buttonDisabled ?
                        <Typography variant="caption" style={{paddingTop:3, color:"red"}}>
                          Täytä yhteystietosi ja hyväksy tietosuojaseloste
                        </Typography> : null}
                      </Grid>
                    </Grid>
                    
                  </Grid>
                </Grid>
                <Dialog open={openEndDialog}>
                  <DialogTitle>
                    {!lottery.includes("no") 
                    ? "Kiitos vastauksistasi ja onnea arvontaan!" 
                    : "Kiitos vastauksistasi!"}
                  </DialogTitle>
                  {!answer2nd.includes("no") ?
                  <DialogContent>
                    <DialogContentText style={{marginBottom:5}}>
                      {answer2nd.includes("yes")
                      ? "Ohjaamme sinut nyt toiseen kyselyyn"
                      : 'Jos annat suostumuksesi, lähetämme sinulle kutsun toiseen kyselyyn sähköpostiosoitteeseesi ' + emailTemp}
                    </DialogContentText>
                    {answer2nd.includes("later") && lottery.includes("no") ?
                    <TextField
                      autoFocus
                      margin="dense"
                      id="email2"
                      label="E-mail"
                      type="email"
                      fullWidth
                      variant="standard"
                      onChange={handleChange}
                    /> : null}
                    <DialogContentText style={{paddingTop: 10, paddingBottom:5}}>
                      {answer2nd.includes("yes")
                      ? null
                      : "Osoitetta ei käytetä muihin tarkoituksiin"}
                    </DialogContentText>
                    {answer2nd.includes("later") ?
                    <FormGroup style={{margin:5, paddingTop:5}}>
                      <FormControlLabel control={
                        <Checkbox checked={gdprAgreedPartner} 
                                  onChange={handleGdprAgreedPartnerChange}
                                  inputProps={{ 'aria-label': 'controlled' }}/>} 
                          label={
                          <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                            {"Sallin kyselykutsun lähettämisen sähköpostiosoitteeseeni. Hyväksyn henkilötietojeni käsittelyn Palbassador Oy:n "}
                            <Link to="/palbassador/arvontarekisteri">tietosuojaselosteen</Link> 
                            {" mukaisesti."}
                          </Typography>
                        }/>
                    </FormGroup> : null}
                  </DialogContent>
                  : null}
                  <DialogActions>
                    {answer2nd.includes("later")
                    ? <Button disabled={waiting}
                              onClick={() => handleSend(true)}>
                        Älä lähetä
                      </Button>
                    : null}
                    {answer2nd.includes("later")
                    ? <Button onClick={() => handleSend(false)} 
                        disabled={waiting ||
                                  (!gdprAgreedLottery && 
                                   (!gdprAgreedPartner || !validator.isEmail(email2))) ||
                                  (gdprAgreedLottery && !gdprAgreedPartner)}>
                          Lähetä
                      </Button>
                    : <Button disabled={waiting} onClick={() => handleSend(false)}>OK</Button>}
                  </DialogActions>
                </Dialog>
              </Grid>
              : (firstEntry ? 
                <Grid container direction="column" justifyContent="center"
                      alignItems="center" style={{height:"100%"}}> 
                  <Grid>
                    <CircularProgress />
                  </Grid>
                </Grid>
              :
                <Grid container direction="column" justifyContent="space-around"
                      style={{width:"100%", height:"100%"}}>               
                  <Grid style={{height:"100%", width:"100%"}}>
                    <iframe height={"100%"} width={"100%"}
                            style={{border:'none'}} 
                            src={querySrc} ></iframe>
                  </Grid>
                </Grid>)
              }
          /> )}
          <Route path="/haggman/arvonta" element={            
            <Grid container direction="column" justifyContent="flex-start"
               alignItems="center"
               style={{height:"100%"}}> 
              <Grid style={{padding:20, width:"100%", maxWidth:1000}}>
                <Grid item style={{padding:10}}>
                  <Typography align="center" variant="h5"
                        style={{padding:20}}>Arvonnan säännöt
                  </Typography>
                </Grid>
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    Arvonnan järjestäjä
                  </Typography>
                  <Typography>
                    Häggman Oy, Kauppakatu 16, 85800 Haapajärvi
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    Arvontaan osallistumisen alkamis- ja päättymisaika
                  </Typography>
                  <Typography>
                    1.3.2023-14.3.2023
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    Arvontaan osallistuminen
                  </Typography>
                  <Typography>
                   Arvontaan osallistuvat kaikki osallistumisaikana Häggmanin asiakaskyselyn täyttäneet ja palauttaneet Suomessa asuvat henkilöt, jotka ovat ilmoittaneet halukkuutensa osallistua arvontaan antamalla kyselyn yhteydessä yhteystietonsa. Asiakaskyselyn voi täyttää osallistumisaikana paperimuotoisesti kaikissa Häggmanin kahviloissa (Haapajärvi, Haapavesi, Nivala, Lahti, Iso-Kärkkäinen, Ylivieska Keskusta) sekä digitaalisesti osoitteissa https://www.haggman.fi ja https://www.palautekanava.fi/haggman.
                  </Typography>
                  <Link to="/haggman/arvontarekisteri">
                    Arvonta- ja yhteystietorekisteri
                  </Link>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    Arvonnan palkinnot
                  </Typography>
                  <Typography>
                    3 kappaletta 10 hengen kermakakkuja
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    Arvonnan ajankohta
                  </Typography>
                  <Typography>
                    Arvonta suoritetaan 20.3.2023-26.3.2023 välisenä aikana.
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30, paddingBottom:40}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    Voitosta ilmoittaminen ja palkinnon luovuttaminen
                  </Typography>
                  <Typography>
                    Häggman Oy:n edustaja ilmoittaa voittajille arvonnan voitosta henkilökohtaisesti soittamalla ja/tai sähköpostitse ja sopii palkinnon luovuttamisesta. 
                  </Typography>
                  <Typography style={{marginTop:20}}>
                  Jos arvonnan voittajaan ei saada yhteyttä 5 päivään yhteydenotosta, palkinto arvotaan uudelleen. 
                  </Typography>
                  <Typography style={{marginTop:20}}>
                  Voittajien nimet voidaan julkaista Häggman Oy:n Facebook- ja Instagram-tileillä.
                  </Typography>
                </Grid >
              </Grid>
            </Grid>
          }/>
          <Route path="/palbassador/arvonta" element={            
            <Grid container direction="column" justifyContent="flex-start"
               alignItems="center"
               style={{height:"100%"}}> 
              <Grid style={{padding:20, width:"100%", maxWidth:1000}}>
                <Grid item style={{padding:10}}>
                  <Typography align="center" variant="h5"
                        style={{padding:20}}>Arvonnan säännöt
                  </Typography>
                </Grid>
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    Arvonnan järjestäjä
                  </Typography>
                  <Typography>
                    Palbassador Oy, Reisjärventie 648, 85800 Haapajärvi
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    Arvontaan osallistumisen alkamis- ja päättymisaika
                  </Typography>
                  <Typography>
                    1.3.2023-16.3.2023
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    Arvontaan osallistuminen
                  </Typography>
                  <Typography>
                   Arvontaan osallistuvat kaikki osallistumisaikana Palbassadorin kuluttajakyselyn täyttäneet ja palauttaneet Suomessa asuvat henkilöt, jotka ovat ilmoittaneet halukkuutensa osallistua arvontaan antamalla kyselyn yhteydessä yhteystietonsa. Kyselyn voi täyttää osallistumisaikana digitaalisesti osoitteessa https://www.palautekanava.fi/palbassador. Kyselyyn voi ohjautua myös Häggman Oy:n asiakaskyselyn kautta osoitteessa https://www.haggman.fi tai https://www.palautekanava.fi/haggman.
                  </Typography>
                  <Link to="/palbassador/arvontarekisteri">
                    Arvonta- ja yhteystietorekisteri
                  </Link>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    Arvonnan palkinnot
                  </Typography>
                  <Typography>
                    2 kappaletta 50€:n arvoisia Häggmanin lahjakortteja
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    Arvonnan ajankohta
                  </Typography>
                  <Typography>
                    Arvonta suoritetaan 20.3.2023-26.3.2023 välisenä aikana.
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30, paddingBottom:40}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    Voitosta ilmoittaminen ja palkinnon luovuttaminen
                  </Typography>
                  <Typography>
                    Palbassador Oy:n edustaja ilmoittaa voittajille arvonnan voitosta henkilökohtaisesti soittamalla ja/tai sähköpostitse ja sopii palkinnon luovuttamisesta. 
                  </Typography>
                  <Typography style={{marginTop:20}}>
                  Jos arvonnan voittajaan ei saada yhteyttä 5 päivään yhteydenotosta, palkinto arvotaan uudelleen. 
                  </Typography>
                  <Typography style={{marginTop:20}}>
                  Voittajien nimiä tai muita henkilötietoja ei julkaista.
                  </Typography>
                </Grid >
              </Grid>
            </Grid>
          }/>
          <Route path="/haggman/arvontarekisteri" element={            
            <Grid container direction="column" justifyContent="flex-start"
               alignItems="center"
               style={{height:"100%"}}> 
              <Grid style={{padding:20, width:"100%", maxWidth:1000}}>
                <Grid item style={{padding:10}}>
                  <Typography align="center" variant="h5"
                        style={{padding:20}}>Tietosuojaseloste
                  </Typography>
                </Grid>
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    1. Rekisterinpitäjä
                  </Typography>
                  <Typography>
                    Häggman Oy
                  </Typography>
                  <Typography>
                    Kauppakatu 16
                  </Typography>
                  <Typography>
                    85800 Haapajärvi
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    2. Yhteyshenkilö rekisteriä koskevissa asioissa
                  </Typography>
                  <Typography>
                    Yrjö Häggman
                  </Typography>
                  <Typography>
                    Kauppakatu 16
                  </Typography>
                  <Typography>
                    85800 Haapajärvi
                  </Typography>
                  <Typography>
                    Puhelin: 08 469 5600
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    3. Rekisterin nimi
                  </Typography>
                  <Typography>
                    Häggman Oy:n arvonta- ja yhteystietorekisteri
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    4. Oikeusperuste ja henkilötietojen käsittelyn tarkoitus
                  </Typography>
                  <Typography>
                    EU:n yleisen tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen käsittelylle on henkilön suostumus (dokumentoitu, vapaaehtoinen, yksilöity, tietoinen ja yksiselitteinen).
                  </Typography>
                  <Typography style={{marginTop:20}}>
                    Asiakkaiden henkilötietojen käsittelyn tarkoituksena on
                  </Typography>
                  <Typography style={{marginLeft:10}}>
                    •	arvontojen voittajien arpominen
                  </Typography>
                  <Typography style={{marginLeft:10}}>
                    •	voittajille ilmoittaminen ja voittojen toimittamisesta sopiminen
                  </Typography>
                  <Typography style={{marginLeft:10}}>
                    •	voittajien julkaiseminen Häggman Oy:n sosiaalisen median kanavissa
                  </Typography>
                  <Typography style={{marginLeft:10}}>
                    •	Häggman Oy:n palveluiden kehittämiseen liittyvistä jatkokyselyistä tai -tutkimuksista ja arvonnoista ilmoittaminen. Henkilötietoja ei käytetä muuhun suoramarkkinointiin.
                  </Typography>
                  <Typography style={{marginTop:20}}>
                    Henkilötietoja ei käsitellä enempää kuin on tarpeen edellä mainittujen tarkoitusten kannalta. Henkilötietoja ei käytetä automatisoituun päätöksentekoon tai profilointiin.
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    5. Rekisterin tietosisältö
                  </Typography>
                  <Typography>
                    Seuraavat luonnolliseen henkilöön liittyvät henkilötiedot:
                  </Typography>
                  <Typography style={{marginLeft:10}}>
                    •	nimi
                  </Typography>
                  <Typography style={{marginLeft:10}}>
                    •	sähköpostiosoite
                  </Typography>
                  <Typography style={{marginLeft:10}}>
                    •	puhelinnumero
                  </Typography>
                  <Typography style={{marginTop:20}}>
                    Digitaalisesti kerättyjä henkilötietoja ei yhdistetä henkilötietojen keräämiseen käytettyjen asiakaskyselyiden vastauksiin.
                  </Typography>
                  <Typography style={{marginTop:20}}>
                    Digitaalisesti kerättyihin henkilötietoihin yhdistetään henkilöltä saatu mahdollinen suostumus vastaanottaa ilmoituksia Häggman Oy:n palveluiden kehittämiseen liittyvistä jatkokyselyistä tai -tutkimuksista.
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    6. Säännönmukaiset tietolähteet
                  </Typography>
                  <Typography>
                    Henkilötietoja kerätään rekisteröidyltä henkilöltä itseltään asiakaskyselyiden yhteydessä.
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    7. Tietojen säilytysaika
                  </Typography>
                  <Typography>
                    Henkilötietoja säilytetään seuraavasti:
                  </Typography>
                  <Typography style={{marginLeft:10, marginTop:10}}>
                    •	Arvontaan osallistuneiden henkilöiden henkilötiedot poistetaan pääsääntöisesti mahdollisimman pian, kuitenkin viimeistään 1 kuukauden kuluttua arvonnan suorittamisen jälkeen.
                  </Typography>
                  <Typography style={{marginLeft:10,  marginTop:10}}>
                    •	Arvonnan voittaneen henkilön tietoja voidaan säilyttää tarvittaessa osana kirjanpidon tositeaineistoa kuluvan vuoden ja sitä seuraavan 6 vuoden ajan.
                  </Typography>
                  <Typography style={{marginLeft:10,  marginTop:10}}>
                    •	Niiden henkilöiden osalta, jotka ovat antaneet suostumuksensa vastaanottaa ilmoituksia Häggman Oy:n palveluiden kehittämiseen liittyvistä jatkokyselyistä tai -tutkimuksista, henkilötietoja säilytetään kyseistä tarkoitusta varten enintään 12 kuukauden ajan.
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    8. Tietojen säännönmukaiset luovutukset
                  </Typography>
                  <Typography>
                    Henkilötiedot kerätään ulkoistetun palveluntarjoajan, Palbassador Oy:n, ylläpitämän ohjelmiston kautta. Tietoja ei säännönmukaisesti luovuteta Häggman Oy:n sekä kyseisen palveluntarjoajan ulkopuolelle.
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    9. Tietojen siirto EU:n tai ETA:n ulkopuolelle
                  </Typography>
                  <Typography>
                    Henkilötietoja ei säännönmukaisesti siirretä EU:n tai ETA:n ulkopuolelle.
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30, paddingBottom:40}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    10. Rekisterin suojauksen periaatteet
                  </Typography>
                  <Typography style={{marginTop:20}}>
                    A Manuaalinen aineisto
                  </Typography>
                  <Typography>
                    Rekisterin tietoja saatetaan säilyttää paperisessa muodossa rekisterinpitäjän toimitiloissa lukitussa tilassa. Tietoihin pääsevät käsiksi ainoastaan tietyt rekisterinpitäjän nimeämät vastuuhenkilöt.
                  </Typography>
                  <Typography style={{marginTop:20}}>
                    B Digitaalinen aineisto
                  </Typography>
                  <Typography>
                    Rekisterin tiedot on tallennettu ulkoistetun palveluntarjoajan järjestelmiin, jotka on suojattu asianmukaisien suojausohjelmistojen ja salasanojen avulla. Rekisterin tietoja voidaan säilyttää myös Häggman Oy:n omilla palvelimilla. Sisäänpääsy koneelle ja järjestelmiin edellyttää henkilökohtaisia käyttäjätunnuksia ja salasanan syöttämistä. Tietoihin pääsevät käsiksi ainoastaan tietyt rekisterinpitäjän nimeämät henkilöt ulkoistetun tietojenkäsittelijän vastuuhenkilön ohella. Rekisterinpitäjän tilat ovat vartioidut.
                  </Typography>
                </Grid >
              </Grid>
            </Grid>
          }/>
          <Route path="/palbassador/arvontarekisteri" element={            
            <Grid container direction="column" justifyContent="flex-start"
               alignItems="center"
               style={{height:"100%"}}> 
              <Grid style={{padding:20, width:"100%", maxWidth:1000}}>
                <Grid item style={{padding:10}}>
                  <Typography align="center" variant="h5"
                        style={{padding:20}}>Tietosuojaseloste
                  </Typography>
                </Grid>
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    1. Rekisterinpitäjä
                  </Typography>
                  <Typography>
                    Palbassador Oy
                  </Typography>
                  <Typography>
                    Reisjärventie 648
                  </Typography>
                  <Typography>
                    85800 Haapajärvi
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    2. Yhteyshenkilö rekisteriä koskevissa asioissa
                  </Typography>
                  <Typography>
                    Eeva Lähetkangas
                  </Typography>
                  <Typography>
                    E-mail: eeva.lahetkangas@palbassador.com
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    3. Rekisterin nimi
                  </Typography>
                  <Typography>
                    Palbassador Oy:n arvonta- ja yhteystietorekisteri
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    4. Oikeusperuste ja henkilötietojen käsittelyn tarkoitus
                  </Typography>
                  <Typography>
                    EU:n yleisen tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen käsittelylle on henkilön suostumus (dokumentoitu, vapaaehtoinen, yksilöity, tietoinen ja yksiselitteinen).
                  </Typography>
                  <Typography style={{marginTop:20}}>
                    Asiakkaiden henkilötietojen käsittelyn tarkoituksena on
                  </Typography>
                  <Typography style={{marginLeft:10}}>
                    •	arvontojen voittajien arpominen
                  </Typography>
                  <Typography style={{marginLeft:10}}>
                    •	voittajille ilmoittaminen ja voittojen toimittamisesta sopiminen
                  </Typography>
                  <Typography style={{marginLeft:10}}>
                    • Palbassador Oy:n palveluiden kehittämiseen liittyvistä jatkokyselyistä tai -tutkimuksista ilmoittaminen. Henkilötietoja ei käytetä muuhun suoramarkkinointiin.
                  </Typography>
                  <Typography style={{marginTop:20}}>
                    Henkilötietoja ei käsitellä enempää kuin on tarpeen edellä mainittujen tarkoitusten kannalta. 
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    5. Rekisterin tietosisältö
                  </Typography>
                  <Typography>
                    Seuraavat luonnolliseen henkilöön liittyvät henkilötiedot:
                  </Typography>
                  <Typography style={{marginLeft:10}}>
                    •	nimi
                  </Typography>
                  <Typography style={{marginLeft:10}}>
                    •	sähköpostiosoite
                  </Typography>
                  <Typography style={{marginLeft:10}}>
                    •	puhelinnumero
                  </Typography>
                  <Typography style={{marginTop:20}}>
                    Digitaalisesti kerättyjä henkilötietoja ei yhdistetä henkilötietojen keräämiseen käytettyjen kyselyiden vastauksiin.
                  </Typography>
                  <Typography style={{marginTop:20}}>
                    Digitaalisesti kerättyihin henkilötietoihin yhdistetään henkilöltä saatu mahdollinen suostumus vastaanottaa ilmoituksia Palbassador Oy:n palveluiden kehittämiseen liittyvistä jatkokyselyistä tai -tutkimuksista.
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    6. Säännönmukaiset tietolähteet
                  </Typography>
                  <Typography>
                    Henkilötietoja kerätään rekisteröidyltä henkilöltä itseltään kyselyiden yhteydessä.
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    7. Tietojen säilytysaika
                  </Typography>
                  <Typography>
                    Henkilötietoja säilytetään seuraavasti:
                  </Typography>
                  <Typography style={{marginLeft:10, marginTop:10}}>
                    •	Arvontaan osallistuneiden henkilöiden henkilötiedot poistetaan pääsääntöisesti mahdollisimman pian, kuitenkin viimeistään 1 kuukauden kuluttua arvonnan suorittamisen jälkeen.
                  </Typography>
                  <Typography style={{marginLeft:10,  marginTop:10}}>
                    •	Arvonnan voittaneen henkilön tietoja voidaan säilyttää tarvittaessa osana kirjanpidon tositeaineistoa kuluvan vuoden ja sitä seuraavan 6 vuoden ajan.
                  </Typography>
                  <Typography style={{marginLeft:10,  marginTop:10}}>
                    •	Niiden henkilöiden osalta, jotka ovat antaneet suostumuksensa vastaanottaa ilmoituksia Palbassador Oy:n palveluiden kehittämiseen liittyvistä jatkokyselyistä tai -tutkimuksista, henkilötietoja säilytetään kyseistä tarkoitusta varten enintään 12 kuukauden ajan.
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    8. Tietojen säännönmukaiset luovutukset
                  </Typography>
                  <Typography>
                    Tietoja ei säännönmukaisesti luovuteta Palbassador Oy:n ulkopuolelle.
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    9. Tietojen siirto EU:n tai ETA:n ulkopuolelle
                  </Typography>
                  <Typography>
                    Henkilötietoja ei säännönmukaisesti siirretä EU:n tai ETA:n ulkopuolelle.
                  </Typography>
                </Grid >
                <Grid item style={{backgroundColor:"white", paddingTop:30, paddingBottom:40}}>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    10. Rekisterin suojauksen periaatteet
                  </Typography>
                  <Typography>
                    Rekisterin tietoja ei säilytetä paperisena. Rekisterin tiedot on tallennettu ulkoistetun palveluntarjoajan palvelimelle, joka on suojattu asianmukaisien suojausohjelmistojen ja salasanojen avulla. Rekisterin tietoja voidaan säilyttää myös Palbassador Oy:n omilla palvelimilla. Sisäänpääsy koneelle ja järjestelmiin edellyttää henkilökohtaisia käyttäjätunnuksia ja salasanan syöttämistä. Tietoihin pääsee käsiksi ainoastaan rekisterinpitäjä.
                  </Typography>
                </Grid >
              </Grid>
            </Grid>
          }/>                    
          <Route path="/surveydone" element={            
            <Grid container direction="column" justifyContent="center"
               alignItems="center"
               style={{height:"100%"}}> 
              <Grid>
                <CircularProgress />
              </Grid>
            </Grid>
          }/>
        </Routes>
      </BrowserRouter>
      </div>
  );
}

