// Rest API related constants
//export const SERVER_ADDRESS = "https://palbassador1.ddns.net:5012/api";
//export const SERVER_ADDRESS_DOWNLOAD = "https://palbassador1.ddns.net:5012";

export const SERVER_ADDRESS = !window.REACT_APP_EXAMPLE_EXTERNAL_APP_MODE 
                                //? "http://palbassador1.ddns.net:3000/api"                              
                                  ? "http://127.0.0.1:3000/survey-api"
                                : "http://127.0.0.1:3100/api";
export const SERVER_ADDRESS_DOWNLOAD = !window.REACT_APP_EXAMPLE_EXTERNAL_APP_MODE
                                ? "http://palbassador1.ddns.net:3000/api"   
                                //? "http://127.0.0.1:3000/api"
                                : "http://127.0.0.1:3100/api";
//export const SERVER_ADDRESS_DOWNLOAD = "http://127.0.0.1:3100";
export const PAL_SERVER_ADDRESS = "http://palbassador1.ddns.net:3000/api";
//"http://127.0.0.1:3000/api";

export const ACTIVE_TOKEN_EXPIRE_TIME_S = 30000;// // TODO -> 5 min
export const ACTIVE_TOKEN_OVERLAP_TIME_S = 2; // Max time that old and new tokens' activity times overlap
export const TOKEN_UPDATE_PERIOD = (ACTIVE_TOKEN_EXPIRE_TIME_S - ACTIVE_TOKEN_OVERLAP_TIME_S) * 1000;

export const APP_NAME = !window.REACT_APP_EXAMPLE_EXTERNAL_APP_MODE ? "PALBASSADOR" : "EXAMPLE EXTERNAL APP";
export const APP_MAIN_COLOR = !window.REACT_APP_EXAMPLE_EXTERNAL_APP_MODE ? "#7b214d" : "#548235";

export const PAL_ID = "PAL_DASHBOARD";
export const ID_SEPARATOR = "__";
export const ACTIVATE_BUTTON_MODE_NO_CHECK = "ACTIVATE_BUTTON_MODE_NO_CHECK";
export const ACTIVATE_BUTTON_MODE_CHECK_DEFAULT = "ACTIVATE_BUTTON_MODE_CHECK_DEFAULT";
export const ACTIVATE_BUTTON_MODE_ALL_FILLED = "ACTIVATE_BUTTON_MODE_ALL_FILLED";
export const ACTIVATE_BUTTON_MODE_ALL_FILLED_AND_MODIFIED =
                                     "ACTIVATE_BUTTON_MODE_ALL_FILLED_AND_MODIFIED";

export const ACCORDION_MODE_NORMAL = "ACCORDION_MODE_NORMAL";
export const ACCORDION_MODE_NORMAL_LEFT = "ACCORDION_MODE_NORMAL_LEFT";
export const ACCORDION_MODE_EXTENDED = "ACCORDION_MODE_EXTENDED";
export const ACCORDION_MODE_NONE = "ACCORDION_MODE_NONE";
export const ACCORDION_MODE_NONE_NO_TITLE = "ACCORDION_MODE_NONE_NO_TITLE";

export const MAX_CHAR_LIMIT_FOR_USER_INPUT_DEFAULT = 40;

export const MAX_LOGO_IMAGE_SIZE = 150;
export const MAX_IMAGE_SIZE = 500;

export const MAX_NUM_OF_BENEFITS_PER_CAMPAIGN = 4;

export const MAX_NUM_OF_PAL_MIDDLEMEN = 5;
export const MAX_REWARD_VALUE = 1000;


export const PAL_THEME = {
        typography: {
          fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Open Sans"',
            'Roboto',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
          h3: {
            fontFamily: 'montserrat',
            fontSize: '1.75rem',
          },
          h4: {
            fontFamily: 'montserrat',
            fontSize: '1.5rem',
          },
          h5: {
            fontFamily: 'montserrat',
            fontSize: '1.25rem',
          },
          h6: {
            fontFamily: 'montserrat',
            fontSize: '1rem',
          },
          button: {
            fontFamily: 'montserrat',
          },
          subtitle1: {
            fontFamily: 'montserrat',
          },
          subtitle2: {
            fontFamily: 'montserrat',
          },
          body1: {
            //fontFamily: 'montserrat',
          }
        },
        palette: {
            primary: {
              main: APP_MAIN_COLOR,//'#92D050', //'#7b214d',
            },
            secondary: {
              main: '#d6d4d0',
            },
            text: {
              primary: '#2E2E2E',
              secondary: '#4F4F4F',
            },
            tonalOffset: 0.25,
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1700,
          },
        }
};


