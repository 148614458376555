import axios from 'axios';
import axiosRetry from 'axios-retry';
import {SERVER_ADDRESS,  ACTIVE_TOKEN_EXPIRE_TIME_S,
        PAL_SERVER_ADDRESS} from "./general_constants";

// General method for API post and get requests
const send_serverAPI_request = (method, base_url_extension, activeToken, params, 
    errorid, cb_nok, cb_ok,dest) => {

  
  //let call_api = "http://127.0.0.1:3000" + base_url_extension;
  let call_api = "https://palbassador1.ddns.net:3000" + base_url_extension;
  //console.log("api:", call_api)
  
  let config = {};
  const client = axios.create({});

  // Set active token to request header
  if (activeToken !== undefined && activeToken !== "") {
    config = {headers: { 'Authorization': `Bearer ${activeToken}` }};
  }
  if (base_url_extension.includes("download") ||
      base_url_extension.includes("updateUserPalCampaignItem")) {
    config = {headers: { 'Authorization': `Bearer ${activeToken}` }, 
              responseType: "arraybuffer"};
  }
  if (base_url_extension.includes("upload")) {
    config = {headers: { 
                Accept: "application/json",
                'Authorization': `Bearer ${activeToken}`,
                'Content-Type': 'multipart/form-data' }};
    axiosRetry(client, {retries: 5, retryCondition: (_error) => true,
               retryDelay: (retryCount) => {return retryCount * 100;}})
  }

  // In case of login request (that returns new token), set token expiration time (ttl)
  if (base_url_extension === "/palUsers/login" && method === "post") {
    params.ttl = ACTIVE_TOKEN_EXPIRE_TIME_S;
    //console.log("send_server_request.jsx: params: ", params);
  }

  let responsedata = "";

  let promise = "";
  if (method === "post") { promise = client.post(call_api,params,config)}
  else if (method === "get") { promise = client.get(call_api,config)}
  else if (method === "patch") {promise = client.patch(call_api,params,config)}
  else if (method === "put") {promise = client.put(call_api,params,config)}
  else if (method === "delete") {promise = client.delete(call_api,config)}

  promise
  .then(responsedata = function (response) {
    //console.log(response);
    return response.data;
  })
  .catch(responsedata = function (error) {
    console.log("send_serverAPI_request.js error:", error);
    if (cb_nok) cb_nok(errorid, parseInt(error.message.split("status code")[1]));
    //return "RESPONSEDATA_ERROR";
  })
  .then(function(responsedata) {
    //if (errorid === "ERROR_BRAND_ADMIN_REG") {responsedata = ""}
    if (responsedata !== "RESPONSEDATA_ERROR" && responsedata !== undefined) {
      if (cb_ok) cb_ok(responsedata);
    } else {
    //  if (cb_nok) cb_nok(errorid);
    }
  });

}

const send_palserverAPI_request = (method, base_url_extension, activeToken, params, errorid, cb_nok, cb_ok) => {
   return send_serverAPI_request(method, base_url_extension, activeToken, params, errorid, cb_nok, cb_ok, "PAL");
}

export {send_serverAPI_request, send_palserverAPI_request};